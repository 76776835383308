import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from "gatsby"
import axios from 'axios'

import EventItem from './EventItem';
import './EventList.css';

const EventsList = ({ limit }) => {

    const [posts, setPosts] = useState([])
    const [loading, setLoading] = useState(true)

    const getPosts = async () => {
        try {
            const userPosts = await axios.get("https://rest.bandsintown.com/v4/artists/id_1408635/events?app_id=d955c710fcc470665a8166b2d950e7c5")

            setPosts(userPosts.data);
            setLoading(false);

        } catch (err) {
            console.error(err.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        getPosts()
    }, [])  // includes empty dependency array

    const ldJson = [];

    return (
    <>
        { !limit && posts.length > 0 ? <div className="col-span-6 md:col-span-12">
            <a href={`https://www.bandsintown.com/a/1408635?came_from=267&app_id=d955c710fcc470665a8166b2d950e7c5&trigger=track`}
                className="inline-block text-red underline relative z-10 mb-16">Track</a> to get concert, live stream and tour updates.
        </div> : ''}

        <div className={`event-list ${loading ? "event-list--loading" : "event-list--loaded"}`}>

            {posts.length > 0 ? posts.map((item, index) => {
                if (index >= limit) {
                    return null;
                }

                let hasTickets = item.offers.some(offer => offer.type === 'Tickets' && offer.status === "available");
                
                let performers = []

                performers = item.lineup.map(function (e, i) {
                    var obj = {};

                    obj["@type"] = 'MusicGroup';
                    obj['name'] = e;

                    return obj;
                });

                ldJson.push(
                    {
                        "@context": "http://schema.org",
                        "@type": "MusicEvent",
                        "name": item.title,
                        "startDate": item.datetime,
                        "endDate": "2021-07-15",
                        "url": item.url,
                        "location": {
                            "@type": "Place",
                            "name": item.venue.name,
                            "address": {
                                "@type": "PostalAddress",
                                "addressCountry": item.venue.country,
                                "addressRegion": item.venue.region,
                                "addressLocality": item.venue.location
                            },
                            "geo": {
                                "@type": "GeoCoordinates",
                                "latitude": item.venue.latitude,
                                "longitude": item.venue.longitude,
                            }
                        },
                        "performers": performers,
                        "description": item.description,
                        "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode"
                    },
                );

                return (
                    <EventItem 
                        { ...item }
                        hasTickets={ hasTickets }
                    />
                )
            })
            : 
                <div className="col-span-6 md:col-span-12">
                    <div className="flex flex-col justify-between items-start py-16 border-t border-b border-gray-400 mb-32">
                            <h2 className="font-serif text-24 leading-32 mb-16">
                                No upcoming events
                            </h2>
                            <p className="text-16 leading-24">
                                <a href="https://www.bandsintown.com/a/1408635?came_from=267&app_id=d955c710fcc470665a8166b2d950e7c5&trigger=track" className="text-red underline">Track</a> to get concert, live stream and tour updates.
                            </p>
                    </div>
                </div>
            }

            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(ldJson)}
                </script>
            </Helmet>
        </div>
        { !limit ? <div className="col-span-6 md:col-span-12">
            <a className="col-span-6 lg:col-span-3 text-center inline-block text-14 leading-24 py-8 px-32 rounded bg-red-200 mt-32" href='https://www.bandsintown.com/a/1408635?app_id=d955c710fcc470665a8166b2d950e7c5&came_from=242&trigger=play_my_city' target="_blank" rel="noreferrer">Ask to play in your city</a>
        </div> : ''}

        { limit && posts.length > 0 ? <div className="col-span-6 md:col-span-12">
            <Link
                to="/tour"
                className="col-span-6 lg:col-span-3 text-center inline-block text-14 leading-24 py-8 px-32 rounded bg-red-200 mt-32"
            >
                View All
            </Link>
        </div> : ''}
    </>
    )
}

export default EventsList
