import React from 'react'
import { format } from 'date-fns'

const EventItem = ({ 
    id, 
    title,
    url,
    datetime,
    venue,
    lineup,
    hasTickets 
}) => {
    
    const eventDate = format(new Date(datetime), 'E, LLL do yyyy');

    let primaryButtonURL = `https://www.bandsintown.com/e/${id}?app_id=d955c710fcc470665a8166b2d950e7c5&came_from=267&utm_medium=api&utm_source=public_api&utm_campaign=event&trigger=notify_me`;
    let primaryButtonText = `Notify Me`;

    if (hasTickets) {
        primaryButtonURL = `https://www.bandsintown.com/t/${id}?app_id=d955c710fcc470665a8166b2d950e7c5&came_from=267&utm_medium=api&utm_source=public_api&utm_campaign=ticket`;
        primaryButtonText = 'Tickets';
    }

    return (
        <div className="event-item">
            <div className="flex flex-col lg:flex-row justify-between space-y-16 lg:space-y-0 lg:space-x-24 w-full lg:w-9/12">
                <div className=" w-full lg:w-3/12">
                    <time dateTime={ datetime } className="font-serif uppercase tracking-wide">
                        { eventDate }
                    </time>
                </div>
                <div className=" w-full lg:w-6/12">
                    <a className="text-red" href={url} target="_blank" rel="noreferrer">
                        <h3 className="font-serif uppercase tracking-wide mb-16">
                            {title}
                        </h3>
                    </a>
                    <p className="block text-white opacity-80">
                        { lineup.join(', ') }
                    </p>
                </div>
                <div className="w-full lg:w-3/12">
                    <p className="font-serif uppercase tracking-wide">
                        {venue.location}
                    </p>
                </div>
            </div>
            <div className="flex flex-row space-x-24 mt-24 lg:mt-0 lg:justify-end w-full lg:w-3/12 lg:text-right">
                <a
                    className="inline-block text-14 leading-24 py-8 px-32 rounded bg-red-200"
                    href={primaryButtonURL}
                    target="_blank"
                    rel="noreferrer"
                >
                    {primaryButtonText}
                </a>
                <a className="inline-block text-14 leading-24 py-8 px-32 rounded bg-black border-red-200 border" href={`https://www.bandsintown.com/e/${id}?app_id=d955c710fcc470665a8166b2d950e7c5&came_from=267&utm_medium=api&utm_source=public_api&utm_campaign=event&trigger=rsvp_going`} target="_blank" rel="noreferrer">
                    RSVP
                </a>
            </div>
        </div>
    )
}

export default EventItem